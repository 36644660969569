@mixin center-page 
    max-width: 1440px
    width: 100%
    margin-left: auto
    margin-right: auto
    

@mixin open-animation
    transform: rotate(180deg)

@mixin close-animation
    transform: rotate(360deg)


@mixin mask
    display: flex
    width: 100%
    height:100%
    border-radius: 15px
