@import "./Global/main"

#error
    display: flex
    flex-direction: column
    align-items: center
    color: #FF6060
    font-family: "Montserrat"
    


    h1
        font-size: 288px
        font-weight: 700
        margin:100px 0
 

    h2
        font-size: 36px

    .link
        font-size: 18px
        color: #FF6060
        text-decoration: none
        margin:100px 0
        
        &:hover
            text-decoration: underline