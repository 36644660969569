// Sizes


    // Fonts

$s-font-size: 18px
$sm-font-size: 24px
$m-font-size: 36px
$l-font-size: 42px
$xl-font-size: 48px


// Colors

$main-color: #FF6060

// URL image banner
$imageBannerUrl : "../assets/Image_1-banner.png"