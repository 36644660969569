@import "./Global/main"


#carousel
    width:100%
    display: flex
    align-items: center
    justify-content: space-between
    background-size: 100% 550px
    background-repeat: no-repeat
    position: relative

    img
        width:100%
        height: 415px
        border-radius: 25px
        object-fit: cover
        
        @media (max-width: 700px)
            height: 350px

        @media (max-width: 500px)
            height: 250px
    button 
        font-size:50px
        border: none
        color: $main-color
        background-color: #ffffff00
        position: absolute
        top: 50%
        transform: translateY(-50%)
        &.next
            right:3%
        &.previous
            left: 3%
    



