footer
    max-width: 1440px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    color: #FFFFFF
    background-color: #000000
    font-size: 34px
    margin-top: 50px
    
    
    #footer_text
        display: flex
        flex-direction: column
        align-items: center
        margin: 65px
        margin-bottom: 0 
    p
        margin: 0
        padding: 30px

    @media (max-width: 500px)
        #footer_text
            margin-top: 25px
        
        img
            width:120px
            height: 40px
        p
            font-size: 12px
            display: flex
            align-self: center
            justify-self: center
            padding:25px

    @media (max-width: 300px)
        img
            width:60px
            height: 20px

        p 
            font-size:8px
    
