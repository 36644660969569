@import "./Global/main"



.card
    width:340px
    height: 340px
    border-radius: 15px
    background-size: cover
    text-decoration: none
    margin: 0 2.5% 2.5% 2.5% 

    .maskCard
        background: linear-gradient(0deg, rgba(84,84,84,1) 0%, rgba(255,255,255,0) 37%)
        @include mask
        align-items: flex-end
        
        h2
            margin-left: 10px

        
    
    h2
        font-size: $s-font-size
        color: white


    @media (max-width: 1000px)
        margin: 0 1.5% 1.5% 1.5%
        

    @media (max-width: 750px)
        width:90%
        margin-bottom: 4%