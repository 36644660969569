@import "./Global/main"


.banner
    background-size: cover
    background-repeat:no-repeat
    height: 220px
    font-size: $sm-font-size
    color: white
    border-radius: 15px
    margin-bottom: 50px

    .mask
        @include mask
        background-color: rgba(0, 0, 0, 0.3) 
        justify-content: center
        align-items: center
        

        



        
        


    
     
