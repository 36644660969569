#gallery
    display: flex 
    flex-wrap: wrap
    align-items: center
    justify-content: center
    background-color: #F6F6F6 
    border-radius:25px
    padding-top: 3%
    

